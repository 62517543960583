import React from "react"
// Styles
import "../styles/cookiesPage.sass"

const CookiesPage = () => {
  return (
    <section className="cookies-page">
      <h1> Политика использования файлов cookie </h1>
      <div className="content">
        <h2>Что такое файлы cookie?</h2>
        <div>
          <p>
            Файлы cookie — это текстовые файлы небольшого объема, сохраняемые на
            Вашем компьютере, планшете или мобильном телефоне. Они НЕ являются
            вредоносными для компьютера или безопасности, и вопреки
            распространенному ошибочному мнению не имеют ничего общего с такими
            вирусами, как троянские программы. При посещении Вами веб-сайта
            файлы cookie позволяют веб-сайту «запоминать» Вас и Ваши
            предпочтения для повышения качества взаимодействия с сайтом.
          </p>{" "}
          <p>
            Более подробную информацию о файлах cookie можно найти в Интернете.{" "}
          </p>
        </div>
        <h2>
          Как я могу контролировать настройки, позволяющие использование cookie?
        </h2>
        <div>
          <p>
            Если Вы не хотите получать файлы cookie, Вы можете изменить
            настройки браузера так, чтобы он уведомлял Вас о получении этих
            файлов, или же полностью отказаться от них. Вы также можете удалить
            уже сохраненные файлы cookie.
          </p>
        </div>
        <h2>Как мы используем файлы cookie</h2>
        <div>
          <p>
            Мы используем сервис веб-аналитики Google Analytics, предоставленный
            компанией Google Inc. (Google), который с помощью файлов cookie
            анализирует информацию об использовании нашего веб-сайта в целях
            повышения его качества.
          </p>{" "}
          <p>
            На этом веб-сайте используется функция идентификатора пользователя
            User ID. Идентификатор пользователя User ID является Вашим сетевым
            именем в Google Analytics. Google связывает данные об использовании
            сайта и идентификатор User-ID с Вашим профилем. Google Analytics на
            анонимной основе отслеживает Ваши действия на сайте на разных
            устройствах (планшет, компьютер, смартфон и т.д.).
          </p>{" "}
          <p>
            Данные об использовании сайта, сформированные с помощью файлов
            cookie (в том числе Ваш IP-адрес), будут переданы в компанию Google
            и сохранены на серверах в США. Компания Google использует эту
            информацию для анализа взаимодействия с сайтом, составления для нас
            отчетов об активности на сайте и предоставления других услуг,
            связанных с использованием сайта и Интернета. При необходимости
            компания Google может передать эту информацию третьей стороне, если
            это предписано законом или в случае, если третья сторона выполняет
            обработку данных по поручению Google. Компания Google не будет
            связывать Ваш IP-адрес с другими данными, сохраненными Google.
          </p>
          <p>
            Компания Google получает только идентификатор User-ID, а не
            информацию, содержащуюся в упомянутом выше профиле или другие
            персональные данные. Мы имеем доступ к обобщенному варианту профиля.
            Профиль, связанный с идентификатором пользователя User-ID, не
            содержит никаких Ваших персональных данных.
          </p>{" "}
          <p>
            Вы можете также отказаться от получения файлов cookie, выбрав
            соответствующие настойки браузера, однако обращаем Ваше внимание на
            то, что в этом случае Вы, вероятно, не сможете использовать функции
            этого сайта в полном объеме. Используя данный сайт, Вы соглашаетесь
            с обработкой данных, собранных о Вас, компанией Google указанным
            выше способом и в соответствии с изложенными целями.
          </p>{" "}
          <p>
            Подробнее о файлах cookie можно узнать, пройдя по ссылке{" "}
            <a
              href="http://www.aboutcookies.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://www.aboutcookies.org
            </a>
            .
          </p>
        </div>
        <h2>Договор пользования</h2>
        <div>
          <p>
            Продолжая пользоваться нашим сайтом, Вы соглашаетесь на размещение
            файлов cookie на Вашем устройстве. Если Вы решили отказаться от
            наших файлов cookie, мы не можем гарантировать, что Ваше посещение
            сайта будет таким же успешным, как при получении файлов cookie.
          </p>
        </div>
      </div>
    </section>
  )
}

export default CookiesPage
